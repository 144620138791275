<template>
  <div>
    <label
      class="d-block grey--text mt-6"
      :class="{
        'error--text': videoError,
        'text--darken-1': !videoError
      }"
    >
      {{ label }} {{ required ? '*' : '' }}
    </label>
    <v-row>
      <v-col
        class="pr-1 column"
        tag="v-layout"
        :cols="value ? 6 : 12"
      >
        <video-upload
          ref="video"
          :disabled="disabled"
          placeholder="Enter a url"
          :required="required"
          :storage-key="videoStorageKey"
          :url="value"
          @change="$emit('input', $event)"
          @progress="updateUploadProgress"
          @validate="videoError = $event"
          @clearUploadIntention="useDefaultVideo = false"
        >
          <app-button
            v-if="pitch !== 'learnings'"
            secondary
            :class="{'mt-4': value, 'ml-4': !value}"
            :disabled="disabled"
            @click="setDefaultVideo"
          >
            Default Video
          </app-button>
        </video-upload>
      </v-col>

      <v-col
        v-if="value"
        class="pr-1"
        cols="6"
      >
        <video-display
          edit-mode
          :storage-key="videoStorageKey"
          :url="value"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { DEFAULT_PROJECT_VIDEO } from '@kickbox/common-util/constants/video';
import Parse from '@kickbox/common-util/src/parse';
import { VideoDisplay } from '@kickbox/common-components';
import VideoUpload from '@/components/widgets/VideoUpload';

export default {
  components: {
    VideoUpload,
    VideoDisplay
  },
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      required: true
    },
    pitch: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      videoError: '',
      uploadProgress: {
        segments: 0,
        uploaded: 0,
      },
      useDefaultVideo: false
    };
  },
  computed: {
    videoStorageKey() {
      return this.useDefaultVideo ? DEFAULT_PROJECT_VIDEO : `videos/project-${this.project.id}-${this.pitch}`;
    },
    isUploadInProgress() {
      return !!(this.uploadProgress.segments
        && this.uploadProgress.uploaded < this.uploadProgress.segments);
    }
  },
  methods: {
    updateUploadProgress(progress) {
      this.uploadProgress = progress;
      this.$emit('isUploadInProgress', this.isUploadInProgress);
    },
    async setDefaultVideo() {
      const defaultVideoUrl = await Parse.Cloud.run('getFileUrl', { key: DEFAULT_PROJECT_VIDEO });
      this.$emit('input', defaultVideoUrl.split('?')[0]);
      this.useDefaultVideo = true;
      this.videoError = '';
    },
    save() {
      return this.$refs.video.save();
    },
    validate() {
      // this.videoError = !this.value;
      return !!this.value;
    }
  }
};
</script>

<style scoped lang="scss">
label {
  font-size: 12px;
}
</style>
