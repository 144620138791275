<template>
  <div class="mt-4">
    <v-subheader class="pa-0">
      Sponsors *
    </v-subheader>
    <v-row
      v-for="(sponsor, index) in value"
      :id="`sponsor_${index}`"
      :key="index"
      no-gutters
    >
      <v-col>
        <v-text-field
          v-model="sponsor.name"
          v-ruid="'sponsorName'"
          :rules="[rules.required]"
          class="sponsorField"
          label="Name *"
          placeholder="Enter a name..."
          required
          @change="registerSponsorEdit(index)"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="sponsor.unit"
          v-ruid="'sponsorUnit'"
          class="sponsorField"
          :items="units"
          item-text="name"
          item-value="name"
          label="Unit *"
          placeholder="Select a unit..."
          return-object
          :rules="[rules.required]"
          @change="registerSponsorEdit(index)"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="sponsor.amount"
          v-ruid="'sponsorAmount'"
          :rules="[rules.required, rules.positiveNumber]"
          class="sponsorField"
          label="Amount *"
          placeholder="Enter an amount..."
          required
          type="number"
          @change="registerSponsorEdit(index)"
        />
      </v-col>
      <v-btn
        class="align-self-center"
        icon
        @click="removeSponsor(index)"
      >
        <v-icon>
          delete
        </v-icon>
      </v-btn>
    </v-row>
    <div>
      <app-button
        secondary
        class="mb-4 mt-2"
        @click="addSponsor"
      >
        Add Sponsor
      </app-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidateMixin } from '@kickbox/common-admin';

export default {
  mixins: [ValidateMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      updatedSponsors: [],
      deletedSponsors: []
    };
  },
  computed: {
    ...mapGetters(['units'])
  },
  methods: {
    registerSponsorEdit(index) {
      if (this.value[index].id) {
        this.updatedSponsors.push(this.value[index]);
        this.$emit('updateSponsor', this.updatedSponsors);
      }
    },
    removeSponsor(index) {
      if (this.value[index].id) {
        this.deletedSponsors.push(this.value[index]);
        this.$emit('deleteSponsor', this.deletedSponsors);
      }
      this.value.splice(index, 1);
    },
    addSponsor() {
      this.value.push({ name: '', unit: '', amount: null });
    },
  },
};
</script>

<style lang="scss" scoped>
.sponsorField {
  width: 90%;
}
</style>
