<template>
  <div>
    <v-text-field
      v-model.number="value"
      clearable
      :disabled="disabled"
      hint="Enter an amount of days that is added to or deducted from the project's phase duration"
      label="Add or Deduct Days"
      placeholder="Enter amount..."
      :rules="[rules.validDuration]"
      type="number"
    />
    <p class="my-1">
      Phase End Date:
      <span :class="{ 'new-date': endDateChanged }">{{ endDate }}</span>
    </p>
    <p class="mt-1 mb-3">
      Project Active Time:
      {{ activeTime }}
      {{ activeTime === 1 ? 'day' : 'days' }}
    </p>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ProjectDuration',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    phase: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      value: 0,
      endDate: moment(this.phase.end).format('MMM DD, YYYY'),
      endDateChanged: false,
      activeTime: moment().startOf('day').diff(moment(this.phase.start), 'days'),
      rules: {
        validDuration: (value) => this.checkValue(value)
            || `You cannot deduct days beyond reaching the starting date: ${moment(this.phase.start).format('MMM DD, YYYY')}.`
      }
    };
  },
  watch: {
    value() {
      if (this.value === '0' || this.value === '') {
        this.endDateChanged = false;
        this.endDate = moment(this.phase.end).format('MMM DD, YYYY');
      } else {
        this.endDateChanged = true;
        this.endDate = moment(this.phase.end).add(this.value, 'd').format('MMM DD, YYYY');
      }
    }
  },
  methods: {
    checkValue(value) {
      if (Math.sign(value) === -1) {
        const delta = moment(this.phase.end).diff(moment(this.phase.start), 'days');
        if (Math.abs(value) > delta) {
          this.$emit('error', true);
          return false;
        }
      }
      this.$emit('value', value);
      this.$emit('error', false);
      return true;
    }
  }
};
</script>

<style scoped>
p {
  color: rgba(0, 0, 0, .54);
  font-size: 13px;
}
.new-date {
  font-weight: 700;
}
</style>
